export const watch = {
  hoaId() {
    this.getRefreshedVehicles()
  },
  selectedRow(row) {
    if (this.isDebug == true) console.debug('selected', { row })

    if (row && row != undefined) {
      this.formData.vehicleID = row.vehicleID
      this.formData.Year = row.year
      this.formData.Make = row.make
      this.formData.Model = row.model
      this.formData.Color = row.color
      this.formData.LicensePlateNumber = row.licensePlateNumber
      this.formData.stateID = row.licensePlateStateID
    }

    if (this.isDebug == true)
      console.debug('update formData values=' + JSON.stringify(this.formData))
  },
  vehicleList() {
    this.updateRows()
  }
}
