<template>
  <PageContentLayoutOnly :aria-label="$t('vehicles.title')" role="region">
    <b-button
      class="is-rounded"
      type="is-primary"
      @click="openVehicleModal()"
      :style="{ marginBottom: '20px' }"
      >Add Vehicle</b-button
    >

    <ModernTable
      :columns="columns"
      :rows="rows"
      :filters="{
        show: false
      }"
      headerColor="#FFF"
    />

    <Modal :toggle.sync="showModal" :styles="{ modal: { height: '70vh' } }">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div class="column is-12">
                <valid-input
                  type="hidden"
                  vid="vehicleID"
                  v-model="formData.vehicleID"
                  :style="{ visibility: 'hidden', position: 'absolute' }"
                />
                <valid-input
                  style="width:50px !important;"
                  name="Year"
                  label="Year"
                  maxlength="50"
                  type="text"
                  vid="Year"
                  placeholder="Year"
                  spellcheck="true"
                  aria-label="Year"
                  rules="max:50|required"
                  class="is-small"
                  v-model="formData.Year"
                />
              </div>
              <div class="column is-8" v-if="statesList">
                <valid-select
                  label="States"
                  placeholder="States"
                  vid="stateID"
                  rules="required"
                  v-model="formData.stateID"
                >
                  <option v-for="option in statesList" :value="option.value" :key="option.value">{{
                    option.label
                  }}</option>
                </valid-select>
              </div>
              <div class="column is-12">
                <valid-input
                  style="width:50px !important;"
                  name="Make"
                  label="Make"
                  maxlength="50"
                  type="text"
                  vid="Make"
                  placeholder="Make"
                  spellcheck="true"
                  aria-label="Make"
                  rules="max:50|required"
                  class="is-small"
                  v-model="formData.Make"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  style="width:50px !important;"
                  name="Model"
                  label="Model"
                  maxlength="50"
                  type="text"
                  vid="Model"
                  placeholder="Model"
                  spellcheck="true"
                  aria-label="Model"
                  rules="max:50|required"
                  class="is-small"
                  v-model="formData.Model"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  style="width:50px !important;"
                  name="Color"
                  label="Color"
                  maxlength="50"
                  type="text"
                  vid="Color"
                  placeholder="Color"
                  spellcheck="true"
                  aria-label="Color"
                  rules="max:50"
                  class="is-small"
                  v-model="formData.Color"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  style="width:50px !important;"
                  name="LicensePlateNumber"
                  label="License Number"
                  maxlength="50"
                  type="text"
                  vid="LicensePlateNumber"
                  placeholder="License Plate Number"
                  spellcheck="true"
                  aria-label="LicensePlateNumber"
                  rules="max:50"
                  class="is-small"
                  v-model="formData.LicensePlateNumber"
                />
              </div>
              <div class="column is-12">
                <button
                  v-if="action && action == 'add'"
                  type="submit"
                  :disabled="loading"
                  class="button is-primary is-rounded"
                  :style="{ float: 'right' }"
                >
                  Add
                </button>
                <button
                  v-if="action && action == 'update'"
                  @click.prevent="updateVehicle(selectedVehicle, selectedID)"
                  class="button is-primary is-rounded"
                  :style="{ float: 'right' }"
                >
                  Update
                </button>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import _get from 'lodash/get'
import kms from '@/services/kms'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'

// components
import Modal from '@/components/Modal'
import ModernTable from '@/components/tables/Modern/Table'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: {
    Modal,
    ModernTable,
    PageContentLayoutOnly,
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    kms: {
      type: Object,
      default: () => {
        return kms
      }
    }
  },

  data,

  created() {
    this.getRefreshedVehicles()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('unit', ['statesList'])
  },

  mounted() {
    this.ownerID = _get(this, '$store.state.hoa.unit.primaryOwner.ownerID', null)

    this.getRefreshedVehicles()
    if (this.isDebug == true) console.debug('mounted ownerID=' + this.ownerID)

    this.$store.dispatch('unit/getStatesList')

    this.resetForm()
  },

  watch,
  methods,

  i18n: {
    messages: {
      en: { vehicles: { title: 'Vehicles' } }
    }
  }
}
</script>
