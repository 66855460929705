import moment from 'moment'

let DEFAULT_FORM_VALUES = {
  stateID: 0,
  vehicleID: 0,
  year: '',
  make: '',
  model: '',
  color: '',
  licensePlateNumber: '',
  licensePlateState: ''
}

export const data = function() {
  return {
    DEFAULT_FORM_VALUES,
    formComplete: false,
    formData: {
      ...DEFAULT_FORM_VALUES
    },
    loading: true,
    isDebug: false,
    confirmModal: false,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    formatFn: function(value) {
      return value != null ? value : ''
    },
    formatNumberFn: function(value) {
      return value != null ? value : 0
    },
    selectedRow: null,
    vehicleList: [{}],
    ownerID: null,
    selectedID: null,
    selectedVehicle: '',
    formShow: false,
    action: '',
    hasPermission: false,

    showModal: false,

    rows: [],
    columns: [
      {
        field: 'year',
        label: 'Year',
        width: '5%',
        sortable: true,
        searchable: true
      },
      {
        field: 'make',
        label: 'Make',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'model',
        label: 'Model',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'color',
        label: 'Color',
        width: '10%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'license',
        label: 'License',
        width: '10%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'state',
        label: 'State',
        width: '10%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'modifiedDate',
        label: 'Modified Date',
        width: '10%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'actions',
        label: 'Actions',
        width: '20%'
      }
    ]
  }
}
