import _get from 'lodash/get'
//
import Actions from './../component/Actions'
import { notifyMessage, notifyProblem } from '@/services/notify'
import { parseDate } from '@/utilities/Date/parse'
//
export const defaultDateFormat = 'MM/dd/yyyy'

export const methods = {
  updateRows() {
    const dateFormat = 'LL'

    this.rows = this.vehicleList.map(row => {
      const modifiedDate = parseDate(_get(row, 'modifiedDate', ''), dateFormat)

      const id = _get(row, 'vehicleID', '')
      const year = _get(row, 'year', '')
      const make = _get(row, 'make', '')
      const model = _get(row, 'model', '')

      return {
        year,
        make,
        model,
        color: _get(row, 'color', ''),
        license: _get(row, 'licensePlateNumber', ''),
        state: _get(row, 'state.name'),
        modifiedDate: {
          date: modifiedDate,
          format: dateFormat
        },
        actions: {
          component: Actions,
          props: {
            vehicleID: id,
            year,
            make,
            model,
            loadUpdateVehicle: _id => {
              this.selectedVehicle = this.vehicleList.find(v => {
                return v.vehicleID === _id
              })

              this.loadUpdateVehicle(_id)
            },
            confirmDeleteVehicle: (_id, _year, _make, _model) => {
              this.confirmDeleteVehicle(_id, _year, _make, _model)
            }
          }
        }
      }
    })
  },

  confirmDeleteVehicle(id, year, make, model) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Vehicle',
      message: `Are you sure you want to <b>delete</b> this ${year} ${make} ${model}?`,
      confirmText: 'Delete Vehicle',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteVehicle(id)
    })
  },
  confirmVehicleUpdate(id) {
    this.$buefy.dialog.confirm({
      title: 'Updating Vehicle',
      message: `Are you sure you want to <b>update</b> this ${this.selectedVehicle.year} ${this.selectedVehicle.make} ${this.selectedVehicle.model}?`,
      confirmText: 'Update Vehicle',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.updateVehicle(id),
      onCancel: () => this.getRefreshedVehicles()
    })
  },
  async resetForm() {
    this.loading = true
    const DEFAULT_FORM_VALUES = this.DEFAULT_FORM_VALUES
    this.formData = { ...DEFAULT_FORM_VALUES }
    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    this.loading = false
  },

  openVehicleModal() {
    this.resetForm()
    this.selectedID = null
    // this.formShow = !this.formShow
    this.action = 'add'
    this.showModal = true
  },

  loadVehicle() {
    this.resetForm()
    this.selectedID = null
    this.formShow = !this.formShow
    this.action = this.action == '' ? 'add' : ''
  },
  loadUpdateVehicle(id) {
    if (this.isDebug == true) console.debug('loadUpdateVehicleID=' + id)

    if (this.selectedVehicle && this.selectedVehicle != undefined) {
      this.formData.vehicleID = this.selectedVehicle.vehicleID
      this.formData.Year = this.selectedVehicle.year
      this.formData.Make = this.selectedVehicle.make
      this.formData.Model = this.selectedVehicle.model
      this.formData.Color = this.selectedVehicle.color
      this.formData.LicensePlateNumber = this.selectedVehicle.licensePlateNumber
      this.formData.stateID = this.selectedVehicle.licensePlateStateID
    }

    this.selectedID = id
    this.formShow = true
    this.action = 'update'

    this.showModal = true
  },
  initButtons() {
    this.selectedID = null
    this.formShow = false
    this.action = ''
  },

  getPayload() {
    if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

    const payload = {
      vehicleID: _get(this, ['formData', 'vehicleID'], null),
      year: _get(this, ['formData', 'Year'], null),
      make: _get(this, ['formData', 'Make'], null),
      model: _get(this, ['formData', 'Model'], null),
      color: _get(this, ['formData', 'Color'], null),
      licensePlateNumber: _get(this, ['formData', 'LicensePlateNumber'], null),
      licensePlateStateID: _get(this, ['formData', 'stateID'], null),
      ownerID: _get(this, ['ownerID'], null)
    }

    if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

    return payload
  },

  // api calls
  async getRefreshedVehicles() {
    if (this.ownerID && this.ownerID != undefined) {
      const params = {
        ownerID: this.ownerID
      }

      if (this.isDebug == true) console.debug('params=' + JSON.stringify(params))

      const { results } = await this.kms.get(`/Roster/Vehicle/List`, {
        params
      })

      this.vehicleList = results

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(this.vehicleList))

      this.initButtons()
    }
  },
  async deleteVehicle(id) {
    if (id <= 0) {
      notifyProblem('Unable to delete this vehicle.')
      return
    }

    try {
      this.loading = true
      const data = { vehicleID: id, forceDelete: false }
      const path = `/Roster/Vehicle/${id}?forceDelete=false`
      const results = await this.kms.delete(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results.recordID >= 0) {
        this.formComplete = true

        this.getRefreshedVehicles()

        notifyMessage(`The selected vehicle was successfully deleted.`)
      } else {
        notifyProblem('There was a problem deleting this vehicle.')
      }
    } catch (e) {
      notifyProblem(e)
    }

    this.loading = false
  },
  async updateVehicle() {
    if (!this.formData || !this.ownerID) {
      notifyProblem('Unable to update this vehicle.')
      return
    }

    try {
      this.loading = true

      const path = `/Roster/Vehicle`
      const results = await this.kms.put(path, this.getPayload())

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results.vehicleID >= 0) {
        this.formComplete = true
        this.resetForm()
        this.getRefreshedVehicles()

        notifyMessage(`The selected vehicle was successfully updated.`)

        this.showModal = false
      } else {
        notifyProblem('There was a problem updating this vehicle.')
      }
    } catch (e) {
      notifyProblem(e)
    }
    this.loading = false
  },
  async onFormSubmit() {
    console.debug('in addVehicle ownerID=' + this.ownerID)

    if (
      this.ownerID &&
      this.ownerID != undefined &&
      this.ownerID > 0 &&
      this.formData &&
      this.formData != undefined
    ) {
      try {
        this.loading = true

        const results = await this.kms.post(`/Roster/Vehicle`, this.getPayload())

        if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

        if (results.vehicleID && results.vehicleID > 0) {
          this.formComplete = true
          this.resetForm()
          this.getRefreshedVehicles()
          notifyMessage(`Successfully added vehicle.`)
          this.showModal = false
        } else {
          notifyProblem('There was a problem adding the new vehicle.')
        }
      } catch (e) {
        notifyProblem(e)
      }
    }

    this.loading = false
  }
}
